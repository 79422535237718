"use client";

import React, { useState } from "react";
import { cx } from "class-variance-authority";
import Image from "next/image";
import { DownIcon } from "./DownIcon";
import { useEffect } from "react";
import Button from "@/app/components/ui/common/Button/page";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./ContactFoem.css";
export const dynamic = "force-dynamic";
import toast, { Toaster } from "react-hot-toast";
import { Confetti, ConfettiButton } from "./Confetti";
import { useRouter } from "next/navigation";

const ContactForm = ({ contactInfoTags }) => {
    // current tags
    const [currTags, setCurrTags] = useState({
        budgetRange: {
            id: 0,
            attributes: {
                budgetName: "Select a Budget Range",
            },
        },
        // contactNumber: contactInfoTags.contactNumber[0], // first
        contactNumber: contactInfoTags.contactNumber[234], // india
        social: contactInfoTags.social[0],
    });

    const router = useRouter();

    // Open Menu
    const [socialOpen, setSocialOpen] = useState(false);
    const [budgetOpen, setBudgetOpen] = useState(false);
    // const [isConfettiActive, setIsConfettiActive] = useState(false);
    // const [hovered, setHovered] = useState(false);
    // console.log(contactInfoTags.contactNumber.map((i) => i.attributes.countryName === "United States of America" ? i : ""))

    // current tags changer

    const [phoneNumber, setPhoneNumber] = useState("");

    const changePhone = (value) => {
        // const input = e.target.value
        setContactInfo((prevContactInfo) => ({
            ...prevContactInfo,
            contactInfo: {
                ...prevContactInfo.contactInfo,
                contactNumber: value,
            },
        }));
        // setPhoneValidation(validatePhoneNumber(value))
    };
    // const validatePhoneNumber = (phonenumber) => {
    //   const phoneNumberPattern = /^\d{10}$/;
    //   return phoneNumberPattern.test(phonenumber);
    // }

    const changeCurrTags = async (key, value) => {
        setCurrTags({
            ...currTags,
            [key]: value,
        });
        setSocialOpen(false);
        // if (!countryOpen) {
        setBudgetOpen(false);
        // }
    };

    // contact info
    const [contactInfo, setContactInfo] = useState({
        name: "",
        email: "",
        projectDescription: "",
        socialInfo: {
            socialTag: contactInfoTags.social[0].id,
            socialName: contactInfoTags.social[0].attributes.socialName,
            socialUsername: "",
        },
        contactInfo: {
            contactNumberTag: contactInfoTags.contactNumber[0].id,
            contactNumber: "",
        },
        budgetRangeInfo: {
            budgetRangeTag: contactInfoTags.budgetRange[0].id,
            budgetRange: "",
        },
        mediaFiles: [],
        sendNda: true,
    });
    // console.log("contactInfo", contactInfo);

    // contact info changer
    const changeContactInfo = async (key, value) => {
        setContactInfo({
            ...contactInfo,
            [key]: value,
        });
    };

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const handleFileEvent = (e) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        const uploaded = [...uploadedFiles];
        chosenFiles.some((file) => {
            // Check if the file already exists
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        });

        setUploadedFiles(uploaded);
    };

    const [submitStatus, setSubmitStatus] = useState("PENDING");

    const uploadFiles = async () => {
        try {
            let mediaFilesList = [];

            await Promise.allSettled(
                uploadedFiles.map(async (uploadedFile, index) => {
                    // creating form data
                    let formData = new FormData();
                    formData.append("files", uploadedFile);
                    ``;
                    // uploading file
                    const uploadingFile = await fetch(
                        `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/upload`,
                        {
                            method: "POST",
                            headers: {
                                Accept: "application/json",
                                Authorization: `Bearer ${process.env.NEXT_PUBLIC_SUPER_TOKEN}`,
                            },
                            body: formData,
                        }
                    );
                    const uploadingFileData = await uploadingFile.json();
                    mediaFilesList.push(uploadingFileData[0].id);
                })
            );

            return mediaFilesList;
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    const validateInputs = () => {
        let isValid = true;

        // Check if all fields are empty
        const allFieldsEmpty =
            !contactInfo.name.trim() &&
            !contactInfo.email.trim() &&
            !contactInfo.socialInfo.socialUsername.trim() &&
            !(
                contactInfo.contactInfo.contactNumber.trim() ||
                phoneNumber.trim()
            ) &&
            !contactInfo.projectDescription.trim() &&
            !contactInfo.budgetRangeInfo.budgetRange.trim();

        if (allFieldsEmpty) {
            toast.error("Please fill out the form correctly.");
            return false;
        }

        // Validate individual fields
        if (!contactInfo.name.trim()) {
            toast.error("Name is required.");
            isValid = false;
        }

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(contactInfo.email)) {
            toast.error("Invalid email address.");
            isValid = false;
        }

        if (!contactInfo.socialInfo.socialUsername.trim()) {
            toast.error("Social username is required.");
            isValid = false;
        }

        // Validate phone number
        if (
            !(
                contactInfo.contactInfo.contactNumber.trim() ||
                phoneNumber.trim()
            ) &&
            phoneNumber.length < 10
        ) {
            toast.error("Invalid phone number.");
            isValid = false;
        }

        if (!contactInfo.projectDescription.trim()) {
            toast.error("Project description is required.");
            isValid = false;
        }

        if (!contactInfo.budgetRangeInfo.budgetRange.trim()) {
            toast.error("Budget range is required.");
            isValid = false;
        }

        return isValid;
    };

    const submitContactInfo = async () => {
        if (!validateInputs()) {
            return false;
        }

        try {
            let mediaFilesList = await uploadFiles();

            // submitting form
            const submittingForm = await fetch(
                `${process.env.NEXT_PUBLIC_STRAPI_URL}/api/contacts`,
                {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${process.env.NEXT_PUBLIC_SUPER_TOKEN}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        data: {
                            ...contactInfo,
                            mediaFiles: mediaFilesList,
                        },
                    }),
                }
            );
            const submittingFormData = await submittingForm.json();
            // console.log(
            //     "🚀 ~ submitContactInfo ~ submittingFormData:",
            //     submittingFormData
            // );
            // console.log("------------------------->");
            setPhoneNumber("");
            if (submittingFormData.data === null) {
                return false;
            } else {
                return true;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    };

    // border border-gray-400 focus-within:border-regal-purple-light focus:border-regal-purple-light
    const BASE_INPUT = cx(
        "text-[#A1A6B6] bg-white placeholder:text-[#A1A6B6] font-manrope font-normal rounded-lg focus:outline-none py-3 px-4 shadow-contactShadow"
    );

    useEffect(() => {
        const checkSubmitStatus = async () => {
            if (submitStatus === "ERROR") {
                setTimeout(() => {
                    setSubmitStatus("PENDING");
                }, 5000);
            } else if (submitStatus === "SUCCESS") {
                setContactInfo({
                    name: "",
                    email: "",
                    projectDescription: "",
                    socialInfo: {
                        socialTag: "",
                        socialName: "",
                        socialUsername: "",
                    },
                    contactInfo: {
                        contactNumberTag: "",
                        contactNumber: "",
                    },
                    budgetRangeInfo: {
                        budgetRangeTag: "",
                        budgetRange: "",
                    },
                    mediaFiles: [],
                    sendNda: true,
                });
                setPhoneNumber("");
                setCurrTags({
                    budgetRange: {
                        id: 0,
                        attributes: {
                            budgetName: "Select a Budget Range",
                        },
                    },
                    contactNumber: contactInfoTags.contactNumber[234], // india
                    social: contactInfoTags.social[0],
                });
                toast.success(" Your response has been submitted.");
                // setIsConfettiActive(true);
                // setTimeout(() => setIsConfettiActive(false), 3000);
                // setTimeout(() => changeRoute(), 3000);
                setTimeout(() => {
                    setSubmitStatus("PENDING");
                }, 5000);
            }
        };

        checkSubmitStatus();
    }, [submitStatus, contactInfoTags.social, contactInfoTags.contactNumber]);

    // const handleClick = () => {
    //     setIsConfettiActive(true);
    //     setTimeout(() => setIsConfettiActive(false), 3000); // Hide confetti after 3 seconds
    // };

    return (
        <>
            <div className="container relative  mt-16 xl:ml-10">
                <div className="flex flex-col justify-center items-center text-center gap-3.5">
                    <h3 className="font-manrope font-extrabold text-4xl leading-7 tracking-wide">
                        Contact Us
                    </h3>
                    <p className="text-[#5a2cb8]">
                        <a href="tel:+916354953278">+91 63549 53278</a>{" "}
                        <span className="text-black font-extralight">| </span>
                        <a href="mailto:solve@tanthetaa.com">
                            solve@tanthetaa.com
                        </a>
                    </p>
                    <p>
                        Complete the form below and we will contact you to
                        discuss your project.
                    </p>
                </div>
                <div className="py-8 xl:px-8 px-10 ">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                        {/* NAME */}
                        <input
                            autoComplete="off"
                            placeholder="Name"
                            className={cx(BASE_INPUT)}
                            type="text"
                            id="name"
                            name="name"
                            value={contactInfo.name}
                            onChange={(e) =>
                                changeContactInfo("name", e.target.value)
                            }
                        />
                        {/* NAME */}

                        {/* EMAIL */}
                        <input
                            autoComplete="off"
                            placeholder="Email"
                            className={cx(BASE_INPUT)}
                            type="email"
                            id="email"
                            name="email"
                            value={contactInfo.email}
                            onChange={(e) =>
                                changeContactInfo("email", e.target.value)
                            }
                        />
                        {/* EMAIL */}

                        {/* SOCIAL INFO */}
                        <div
                            className={cx(
                                BASE_INPUT,
                                "flex flex-row w-full !pl-0"
                            )}
                        >
                            <div className="dropdown-left-border"></div>
                            <div
                                className="relative flex flex-col items-center w-12 cursor-pointer place-content-center group"
                                onClick={() => setSocialOpen(!socialOpen)}
                            >
                                <div className="flex flex-row items-center w-full space-x-1 place-content-center">
                                    <div
                                        className="relative w-5 h-5"
                                        key={
                                            currTags.social.attributes
                                                .socialLogo.data.attributes.name
                                        }
                                    >
                                        <Image
                                            fill
                                            key={
                                                currTags.social.attributes
                                                    .socialLogo.data.attributes
                                                    .name
                                            }
                                            src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${currTags.social.attributes.socialLogo.data.attributes.url}`}
                                            alt={
                                                currTags.social.attributes
                                                    .socialLogo.data.attributes
                                                    .alternativeText
                                            }
                                            className="object-cover"
                                        />
                                    </div>
                                    <DownIcon classes="w-5 h-5 fill-black p-[0.1rem]" />
                                </div>

                                <div
                                    className={`absolute top-0 left-0 grid grid-cols-1 pt-6 opacity-0 ${
                                        socialOpen
                                            ? "opacity-100 z-10"
                                            : "hidden"
                                    }`}
                                >
                                    <div className="grid grid-cols-1 py-1 bg-white rounded-lg shadow-lg">
                                        {contactInfoTags.social.map(
                                            (currSocial, i) => {
                                                return (
                                                    <div
                                                        key={i}
                                                        className="w-full p-2 hover:bg-slate-300"
                                                    >
                                                        <div
                                                            className="relative w-5 h-5 cursor-pointer"
                                                            onClick={() => {
                                                                changeCurrTags(
                                                                    "social",
                                                                    currSocial
                                                                );
                                                                changeContactInfo(
                                                                    "socialInfo",
                                                                    {
                                                                        ...contactInfo.socialInfo,
                                                                        socialTag:
                                                                            currSocial.id,
                                                                        socialName:
                                                                            currSocial
                                                                                ?.attributes
                                                                                ?.socialName,
                                                                    }
                                                                );
                                                                // setSocialOpen(true);
                                                            }}
                                                        >
                                                            <Image
                                                                fill
                                                                key={
                                                                    currSocial
                                                                        .attributes
                                                                        .socialLogo
                                                                        .data
                                                                        .attributes
                                                                        .name
                                                                }
                                                                src={`${process.env.NEXT_PUBLIC_STRAPI_URL}${currSocial.attributes.socialLogo.data.attributes.url}`}
                                                                alt={
                                                                    currSocial
                                                                        .attributes
                                                                        .socialLogo
                                                                        .data
                                                                        .attributes
                                                                        .alternativeText
                                                                }
                                                                className="object-cover"
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>

                            <input
                                autoComplete="off"
                                placeholder="Username"
                                className={cx(
                                    "bg-white text-black focus:outline-none col-span-5 ml-3 w-full"
                                )}
                                type="text"
                                id="socialUsername"
                                name="socialUsername"
                                value={contactInfo.socialInfo.socialUsername}
                                onChange={(e) =>
                                    changeContactInfo("socialInfo", {
                                        ...contactInfo.socialInfo,
                                        socialUsername: e.target.value,
                                    })
                                }
                            />
                        </div>
                        {/* SOCIAL INFO */}

                        <div
                            className={cx(
                                BASE_INPUT,
                                "flex flex-row w-full !pl-0"
                            )}
                        >
                            <div className="dropdown-left-border"></div>

                            <PhoneInput
                                type="text"
                                country={"us"}
                                value={phoneNumber}
                                onChange={changePhone}
                                inputProps={{ required: true }}
                                countryCodeEditable={false}
                            />
                        </div>
                        {/* CONTACT NUMBER INFO */}

                        {/* BUDGET RANGE INFO */}
                        <div
                            className={cx(
                                BASE_INPUT,
                                "md:col-span-2 flex flex-col w-full"
                            )}
                        >
                            <div
                                className="relative flex flex-col items-center cursor-pointer place-content-center group"
                                onClick={() => setBudgetOpen(!budgetOpen)}
                            >
                                <div className="flex flex-row items-center w-full space-x-1 place-content-center">
                                    <div
                                        className="w-full h-5 grow"
                                        key={
                                            currTags.budgetRange.attributes
                                                .budgetName
                                        }
                                    >
                                        {
                                            currTags.budgetRange.attributes
                                                .budgetName
                                        }
                                    </div>
                                    <DownIcon classes="w-5 h-5 fill-black p-[0.1rem]" />
                                </div>

                                <div
                                    className={`absolute mt-3 top-0 left-0 grid w-full grid-cols-1 pt-6 opacity-0 -z-10 ${
                                        budgetOpen
                                            ? "opacity-100 z-10"
                                            : "hidden"
                                    }`}
                                >
                                    <div className="grid grid-cols-1 p-2 overflow-y-scroll bg-white rounded-lg shadow-lg max-h-52">
                                        {contactInfoTags.budgetRange.map(
                                            (currBudget) => {
                                                return (
                                                    <div
                                                        key={
                                                            currBudget
                                                                .attributes
                                                                .budgetName
                                                        }
                                                        className="flex flex-row items-center justify-center p-2 space-x-2 rounded-lg hover:bg-slate-300"
                                                        onClick={() => {
                                                            changeCurrTags(
                                                                "budgetRange",
                                                                currBudget
                                                            );
                                                            changeContactInfo(
                                                                "budgetRangeInfo",
                                                                {
                                                                    ...contactInfo.budgetRangeInfo,
                                                                    budgetRangeTag:
                                                                        currBudget.id,
                                                                    budgetRange:
                                                                        currBudget
                                                                            .attributes
                                                                            .budgetName,
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <div className="relative w-full h-5 cursor-pointer">
                                                            {
                                                                currBudget
                                                                    .attributes
                                                                    .budgetName
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BUDGET RANGE INFO */}

                        {/* PROJECT DESCRIPTION */}
                        <textarea
                            placeholder="Project Description"
                            className={cx(BASE_INPUT, "md:col-span-2")}
                            type="text"
                            rows="6"
                            id="projectDescription"
                            name="projectDescription"
                            value={contactInfo.projectDescription}
                            onChange={(e) =>
                                changeContactInfo(
                                    "projectDescription",
                                    e.target.value
                                )
                            }
                        />
                        {/* <div className="absolute w-4/5 h-4/5 bottom-0 border-2 border-green-700">
                        </div> */}

                        {/* PROJECT DESCRIPTION */}

                        {/* UPLOAD MEDIA FILES */}
                        <div className="flex flex-row items-center justify-between mb-8 md:col-span-2 my-2 lg:flex-nowrap form-group">
                            <label className="text-[#A1A6B6] text-base mb-4 lg:mb-0">
                                Attach file (less than 10MB)
                            </label>
                            <div className="cursor-pointer">
                                <input
                                    autoComplete="off"
                                    className={`${
                                        uploadedFiles?.length > 0 ? null : null
                                    } upload-file text-black rounded-lg shadow-contactShadow pr-2 w-[99%] md:w-[100%]`}
                                    name="file"
                                    type="file"
                                    multiple
                                    onChange={handleFileEvent}
                                />
                                {uploadedFiles?.length > 0 &&
                                    uploadedFiles?.map((file, i) => (
                                        <div
                                            key={i}
                                            className={`normal text-sm  mt-1`}
                                        >
                                            {file.name}
                                            {file?.size > 10000000 ? (
                                                <span
                                                    className={`error text-sm  mt-1 mx-1`}
                                                >
                                                    ( File size is greater than
                                                    maximum limit )
                                                </span>
                                            ) : null}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        {/* UPLOAD MEDIA FILES */}

                        {/* SEND NDA */}
                        {/* <div className="flex items-center justify-start mb-10 form-group form-check">
                            <input
                                autoComplete="off"
                                className="w-5 h-5 mr-5 rounded cursor-pointer accent-green-500"
                                type="checkbox"
                                checked={contactInfo.sendNda}
                                onChange={() => {
                                    changeContactInfo(
                                        "sendNda",
                                        !contactInfo.sendNda
                                    );
                                }}
                            />
                            <label className="text-[#A1A6B6] text-base">
                                Send me a copy of NDA
                            </label>
                        </div> */}
                        {/* SEND NDA */}

                        {/* <div className="relative flex flex-col items-center w-full -mt-5 md:col-span-2 place-content-center border-2 border-red-600 z-40">
                            {isConfettiActive && (
                                <Confetti
                                    options={{
                                        particleCount: 300, // Increased particle count
                                        spread: 100, // Wider spread of particles
                                        // gravity: 0.2, // Slower fall speed
                                        // ticks: 700, // Duration of confetti before disappearing
                                    }}
                                    className="absolute z-50 bottom-5"
                                />
                            )}
                            <ConfettiButton
                                options={{ particleCount: 150, spread: 100 }}
                                onClick={handleClick}
                            >
                                Submit Now
                            </ConfettiButton>
                        </div> */}

                        {/* SUBMIT BUTTON */}
                        <div className="relative flex flex-col items-center w-full -mt-5 md:col-span-2 place-content-center">
                            {/* {isConfettiActive && (
                                <Confetti
                                    options={{
                                        particleCount: 500, // Increased particle count
                                        spread: 100, // Wider spread of particles
                                        // gravity: 0.2, // Slower fall speed
                                        // ticks: 700, // Duration of confetti before disappearing
                                    }}
                                    className="absolute z-50 bottom-10 w-4/5 h-[500px]"
                                />
                            )} */}
                            <button
                                className={cx(
                                    "btn-primary-purple !bg-[#410EAD] !text-white !py-3 !px-10 !tracking-wide text-xl"
                                )}
                                onClick={async () => {
                                    setSubmitStatus("SUBMITTING");
                                    const isSubmitted =
                                        await submitContactInfo();
                                    if (isSubmitted === false) {
                                        setSubmitStatus("ERROR");
                                    } else {
                                        setSubmitStatus("SUCCESS");
                                        router.push("/thank-you");
                                    }
                                }}
                            >
                                {submitStatus === "SUBMITTING" ? (
                                    <div
                                        className="inline-block h-5 w-5 animate-spin rounded-full border-2 border-solid border-white border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                        role="status"
                                    >
                                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                            Loader
                                        </span>
                                    </div>
                                ) : (
                                    "Submit Now"
                                )}
                            </button>
                        </div>
                        {/* SUBMIT BUTTON */}

                        {submitStatus === "ERROR" ? (
                            <div className="w-full text-center text-red-500 md:col-span-2">
                                Please enter valid details.
                            </div>
                        ) : submitStatus === "SUCCESS" ? (
                            <div className="w-full text-center text-green-500 md:col-span-2">
                                Your response has been submitted.
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>

                    {/* <div className="text-[#A1A6B6] text-sm text-center mt-10">
                        Your idea is 100% protected by our Non Disclosure
                        Agreement.
                    </div> */}
                </div>
            </div>

            <Toaster position="top-center" reverseOrder={false} />
        </>
    );
};

export default ContactForm;
